import React from 'react'

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton, TwitterIcon, TwitterShareButton,
} from 'react-share'

const shareButtonStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
}

const SocialShare = ({ pageTitle, subline, url }) => {

    console.log('creating social share buttons for page: ', url)

    return (
        <div style={shareButtonStyle}>
            <EmailShareButton subject={`I would like to share: ${pageTitle}`}
                              body={`I would like to share: ${pageTitle}\n${subline}`}
                              url={url}>
                <EmailIcon/>
            </EmailShareButton>
            <FacebookShareButton quote={`I would like to share: ${pageTitle}\n${subline}`}
                                 url={url}>
                <FacebookIcon/>
            </FacebookShareButton>
            <LinkedinShareButton title={pageTitle} summary={`I would like to share: ${pageTitle}\n${subline}`}
                                 source={pageTitle} url={url}>
                <LinkedinIcon/>
            </LinkedinShareButton>
            <TwitterShareButton title={pageTitle} via={`uequations`} url={url}>
                <TwitterIcon/>
            </TwitterShareButton>
        </div>
    )
}

export default SocialShare
